import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { Question } from './types';
import { calculateScores, Scores } from './utils/scoreCalculations';
import QuestionPage from './components/QuestionPage';
import LongFormQuestionPage from './components/LongFormQuestionPage';
import EmailPage from './components/EmailPage';
import ResultsPage from './components/ResultsPage';
import { validateEmail } from './utils/validation';
import LandingPage from './components/LandingPage';
import axios from 'axios';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Advice } from './components/ResultsPage';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://lvi-backend.vercel.app';

type PageType = 'landing' | 'learning' | 'leverage' | 'longform' | 'email' | 'results';

const questionLabels = [
  "Experiment Frequently",
  "Proactively Discover User & Customer Insights",
  "Consistently Analyse Experiment Outcomes",
  "Rapidly Adapt Execution Based on Learnings",
  "Respond Positively to Failed Experiments",
  "Share Ideas Freely",
  "Make Data-Informed Decisions",
  "Regularly Reflect on Learnings",
  "Proactively Share Learnings Across Teams",
  "Enable Cross-Team Efforts Based on Learnings",
  "Build Effective Knowledge Systems",
  "Involve Leaders in Learning Discussions"
];

interface ScatterChartData {
  userData: Array<{ x: number; y: number }>;
  averageData: Array<{ x: number; y: number }>;
}

export interface RadarDataPoint {
  question: string;
  value: number;
}

interface UserData {
  radarChartData: {
    radarData: RadarDataPoint[];
    datasets: Array<{
      id: string;
      data: number[];
      color: string;
    }>;
  };
  scatterChartData: ScatterChartData;
  scores: Scores;
  advice: Array<{
    questionId: number;
    score: number;
    adviceText: string;
    questionLabel?: string;
  }>;
  longFormAnswer: string;
  subheading: string;
  industry: string;
  companySize: string;
  surveyId: number;
}

const App: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<PageType>('landing');
  const [answers, setAnswers] = useState<Record<number, number>>({});
  const [email, setEmail] = useState('');
  const [scores, setScores] = useState<Scores | null>(null);
  const [longFormAnswer, setLongFormAnswer] = useState('');
  const [isPageValid, setIsPageValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [dbQuestions, setDbQuestions] = useState<Question[]>([]);
  const [industry, setIndustry] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [teamType, setTeamType] = useState('');
  const [geography, setGeography] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('useEffect running, calling fetchQuestions');
    }
    fetchQuestions();
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Current dbQuestions:', dbQuestions);
    }
  }, [dbQuestions]);

  useEffect(() => {
    if (currentPage === 'leverage') {
      window.scrollTo(0, 0);
    }
  }, [currentPage]);

  const fetchQuestions = async () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Fetching questions...');
    }
    const { data, error } = await supabase
      .from('questions')
      .select('*')
      .order('id');

    if (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Error fetching questions:', error);
      }
    } else if (data) {
      setDbQuestions(data.map(q => ({
        ...q,
        options: typeof q.options === 'string' ? JSON.parse(q.options) : q.options
      })));
    } else {
      if (process.env.NODE_ENV !== 'production') {
        console.log('No data returned from Supabase');
      }
    }
  };

  const handleStartQuiz = () => {
    setCurrentPage('learning');
  };

  const handleAnswerChange = (questionId: number, value: number) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        [questionId]: value
      };
      
      // Immediately check if all questions for the current page are answered
      const relevantQuestions = dbQuestions.filter(q => q.category === currentPage.toUpperCase());
      const allAnswered = relevantQuestions.every(q => newAnswers[q.id] !== undefined);
      
      // Update isPageValid state immediately
      setIsPageValid(allAnswered);
      
      return newAnswers;
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleNextPage = () => {
    if (isPageValid) {
      if (currentPage === 'learning') {
        setCurrentPage('leverage');
        setCurrentStep(2);
      } else if (currentPage === 'leverage') {
        setCurrentPage('longform');
        setCurrentStep(3);
      } else if (currentPage === 'longform') {
        setCurrentPage('email');
      }
      setIsPageValid(false); // Reset validation for the next page
      window.scrollTo(0, 0); // Scroll to top immediately
    }
  };

  const handleLongFormAnswerChange = (answer: string) => {
    setLongFormAnswer(answer);
    setIsPageValid(answer.length >= 8);
  };

  const validatePage = () => {
    if (currentPage === 'learning' || currentPage === 'leverage') {
      const relevantQuestions = dbQuestions.filter(q => q.category === currentPage.toUpperCase());
      const allAnswered = relevantQuestions.every(q => answers[q.id] !== undefined);
      setIsPageValid(allAnswered);
    } else if (currentPage === 'longform') {
      setIsPageValid(longFormAnswer.length >= 8);
    } else {
      setIsPageValid(true);
    }
  };

  const handleSubmit = async () => {
    if (!turnstileToken) {
      alert("Please complete the Turnstile challenge");
      return;
    }

    setIsLoading(true);
    try {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Submitting form with turnstile token:', turnstileToken);
      }
      const result = await saveSurveyResults();
      if (result) {
        const { scores: calculatedScores, surveyId } = result;
        setScores(calculatedScores);

        // Fetch advice based on scores
        const advice = await getAdviceForScores(
          calculatedScores.individualScores.map(score => ({
            metric: score.question,
            score: score.score
          }))
        );

        // Map question labels to advice
        const adviceWithLabels = advice.map(item => {
          const questionIndex = parseInt(item.questionId.toString()) - 1;
          return {
            ...item,
            questionLabel: questionLabels[questionIndex] || `Question ${item.questionId}`,
          };
        });

        // Prepare radar chart data
        const radarData = calculatedScores.individualScores.map((score, index) => ({
          question: questionLabels[index],
          value: score.score
        }));

        const datasets = [
          {
            id: 'Your Score',
            data: radarData.map(d => d.value),
            color: 'rgba(194,255,9,0.2)',
          }
          // Add more datasets if needed
        ];

        // Prepare scatter chart data
        const scatterChartData = {
          userData: [
            { x: calculatedScores.learningScore, y: calculatedScores.leverageScore },
          ],
          averageData: [
            // Example averages; replace with actual average data if available
            { x: 80, y: 70 }, // Overall Average
            { x: 75, y: 65 }, // Industry Average
            { x: 70, y: 60 }, // Company Size Average
          ],
        };

        // Construct the userData object including scatterChartData
        const constructedUserData: UserData = {
          radarChartData: {
            radarData: radarData,
            datasets: datasets
          },
          scatterChartData: scatterChartData,
          scores: calculatedScores,
          advice: adviceWithLabels,
          longFormAnswer: longFormAnswer,
          subheading: '', 
          industry: industry,        // Ensure these lines are present
          companySize: companySize,  // Ensure these lines are present
          surveyId: surveyId, // Add this line
        };

        setUserData(constructedUserData);

        // Send the userData to the backend
        await sendEmail(surveyId, email, constructedUserData);

        setCurrentPage('results');
      } else {
        if (process.env.NODE_ENV !== 'production') {
          console.error('Failed to save survey results');
        }
      }
    } catch (error) {
      console.error('Error submitting survey:', error);
      if (error instanceof Error) {
        if (error.message.includes('Rate limit exceeded')) {
          alert('You have exceeded the rate limit. Please try again later.');
        } else {
          alert(`An error occurred while submitting the survey: ${error.message}`);
        }
      } else {
        alert('An unknown error occurred while submitting the survey.');
      }
    } finally {
      setIsLoading(false);
      setTurnstileToken(null);
    }
  };

  const getSubheading = () => {
    switch (currentPage) {
      case 'learning': return 'Learning Category';
      case 'leverage': return 'Leverage Category';
      case 'longform': return 'Final Survey Question';
      case 'email': return 'We just need a few more details';
      case 'results': return 'Aggregate Scores';
      default: return '';
    }
  };

  const saveSurveyResults = async () => {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Attempting to save survey results");
      console.log("Current state:", { email, industry, companySize, teamType, geography });
    }

    // First, insert or update the user
    const { error: userError } = await supabase
      .from('users')
      .upsert({ email: email }, { onConflict: 'email' });

    if (userError) {
      console.error('Error creating/updating user:', userError);
      return null;
    }

    // Create a new survey entry
    const { data: surveyData, error: surveyError } = await supabase
      .from('surveys')
      .insert({
        user_email: email,
        industry: industry,
        company_size: companySize,
        team_type: teamType,
        geography: geography
      })
      .select()
      .single();

    if (surveyError) {
      console.error('Error creating survey:', surveyError);
      console.error('Error details:', surveyError.details, surveyError.hint, surveyError.message);
      return null;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log("Survey created successfully:", surveyData);
    }

    const surveyId = surveyData.id;

    // Save answers
    const answersToInsert = Object.entries(answers).map(([questionId, selectedIndex]) => {
      const question = dbQuestions.find(q => q.id === parseInt(questionId));
      const answerValue = question?.options[selectedIndex]?.value || 0;
      return {
        survey_id: surveyId,
        question_id: parseInt(questionId),
        answer: answerValue
      };
    });

    const { error: answersError } = await supabase
      .from('answers')
      .insert(answersToInsert);

    if (answersError) {
      console.error('Error saving answers:', answersError);
    }

    // Save long form answer
    const { error: longFormError } = await supabase
      .from('long_form_answers')
      .insert({ survey_id: surveyId, answer: longFormAnswer });

    if (longFormError) {
      console.error('Error saving long form answer:', longFormError);
    }

    // Calculate scores
    const calculatedScores = calculateScores(
      Object.fromEntries(
        Object.entries(answers).map(([questionId, selectedIndex]) => {
          const question = dbQuestions.find(q => q.id === parseInt(questionId));
          return [questionId, question?.options[selectedIndex]?.value || 0];
        })
      ),
      dbQuestions
    );

    // Save scores
    const { error: scoresError } = await supabase
      .from('scores')
      .insert({
        survey_id: surveyId,
        total_score: calculatedScores.overallScore,
        learning_score: calculatedScores.learningScore,
        leverage_score: calculatedScores.leverageScore
      });

    if (scoresError) {
      console.error('Error saving scores:', scoresError);
    }

    // Fetch advice based on scores
    const advice = await getAdviceForScores(
      calculatedScores.individualScores.map(score => ({
        metric: score.question,
        score: score.score
      }))
    );

    // Generate advice summary and top actions
    const adviceSummary = await generateAdviceSummary(calculatedScores, advice);

    if (process.env.NODE_ENV !== 'production') {
      console.log('Sending to supabase:', surveyId, adviceSummary.summary, adviceSummary.topActions);
    }

    const { error: summaryError } = await supabase
      .from('surveys')
      .update({
        advice_summary: adviceSummary.summary,
        top_actions: adviceSummary.topActions
      })
      .eq('id', surveyId);

    if (summaryError) {
      console.error('Error saving advice summary:', summaryError);
    }

    // Add this block to fire the webhook
    if (surveyId) {
      try {
        await axios.post('https://hook.eu2.make.com/g7btl3pb45x17am084mumk6y39dszqfo', {
          email: email,
          source: 'plgeek',
          medium: 'web',
          referringSite: 'https://lvi.plgeek.com'
        });
        if (process.env.NODE_ENV !== 'production') {
          console.log('Webhook fired successfully');
        }
      } catch (error) {
        console.error('Error firing webhook:', error);
        // Note: We're not throwing an error here to prevent it from affecting the user experience
      }
    }

    return { surveyId, scores: calculatedScores };
  };

  const handleStartAssessment = () => {
    setCurrentPage('learning');
    window.scrollTo(0, 0);
  };

  const getAdviceForScores = async (scores: { metric: string; score: number }[]): Promise<Advice[]> => {
    const advicePromises = scores.map(async (score) => {
      const questionId = parseInt(score.metric.replace('Q', ''));
      if (isNaN(questionId)) {
        console.error(`Invalid question ID: ${score.metric}`);
        return null;
      }
      try {
        const { data, error } = await supabase
          .from('advice')
          .select('*')
          .eq('question_id', questionId)
          .lte('score', score.score)
          .order('score', { ascending: false })
          .limit(1);

        if (error) throw error;

        return data[0] ? {
          questionId: data[0].question_id,
          score: score.score,
          adviceText: data[0].advice_text
        } : null;
      } catch (error) {
        console.error(`Error fetching advice for question ${score.metric}:`, error);
        return null;
      }
    });

    const advice = await Promise.all(advicePromises);
    return advice.filter((item): item is Advice => item !== null);
  };

  const sendEmail = async (surveyId: number, email: string, userData: UserData) => {
    try {
      const userDataWithIndustry = {
        ...userData,
        industry,
        companySize,
      };

      const response = await axios.post(`${API_BASE_URL}/send-results-email`, {
        email: email,
        userData: userDataWithIndustry,
        turnstileToken: turnstileToken
      });

      if (response.status === 200) {
        if (process.env.NODE_ENV !== 'production') {
          console.log('Email sent successfully.');
        }
      } else {
        throw new Error('Failed to send email');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      if (error instanceof Error) {
        if ((error as any).isAxiosError && (error as any).response?.status === 429) {
          throw new Error('Rate limit exceeded');
        }
      }
      throw error;
    }
  };

  // Remove global assignment and handle Turnstile callback locally
  const handleTurnstileVerify = (token: string) => {
    setTurnstileToken(token);
  };

  const generateAdviceSummary = async (scores: Scores, advice: Advice[]): Promise<{ summary: string; topActions: string[] }> => {
    const adviceText = advice.map((a, index) => `${questionLabels[index]}: ${a.adviceText}`).join('\n\n');
    
    try {
      const response = await axios.post(`${API_BASE_URL}/generate-advice-summary`, {
        scores,
        advice: adviceText
      });

      // Type assertion for response.data

     const data = response.data as { summary?: string; topActions?: string[] };

     if (typeof data.summary === 'string' && Array.isArray(data.topActions)) {
        return {
          summary: data.summary,
          topActions: data.topActions[0]
          .split(/\d+\./)
          .filter(Boolean)
          .map(action => action.trim())
         };
      } else {
        throw new Error('Invalid response structure from advice summary generation');
      }


    } catch (error) {
      console.error('Error generating advice summary:', error);
      return {
        summary: "We encountered an error generating your advice summary. Please refer to the detailed advice below for specific recommendations.",
        topActions: ["N/A", "N/A", "N/A"]
      };
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-primary text-white">
      <div className="relative py-3 sm:mx-auto w-full max-w-4xl">
        <div className="relative px-4 py-10 bg-primary shadow-lg sm:rounded-3xl sm:p-20">
          {currentPage === 'landing' && (
            <LandingPage onStartAssessment={handleStartAssessment} />
          )}
          {(currentPage === 'learning' || currentPage === 'leverage') && (
            <QuestionPage
              questions={dbQuestions}
              answers={answers}
              onAnswerChange={handleAnswerChange}
              category={currentPage.toUpperCase() as 'LEARNING' | 'LEVERAGE'}
              onNextPage={handleNextPage}
              subheading={getSubheading()}
              isPageValid={isPageValid}
              currentStep={currentStep}
            />
          )}
          {currentPage === 'longform' && (
            <LongFormQuestionPage
              answer={longFormAnswer}
              onAnswerChange={handleLongFormAnswerChange}
              onNextPage={handleNextPage}
              subheading={getSubheading()}
              isPageValid={isPageValid}
              currentStep={currentStep}
            />
          )}
          {currentPage === 'email' && (
            <EmailPage
              email={email}
              onEmailChange={handleEmailChange}
              onSubmit={handleSubmit}
              subheading={getSubheading()}
              isEmailValid={isEmailValid}
              industry={industry}
              onIndustryChange={setIndustry}
              companySize={companySize}
              onCompanySizeChange={setCompanySize}
              teamType={teamType}
              onTeamTypeChange={setTeamType}
              geography={geography}
              onGeographyChange={setGeography}
              isLoading={isLoading}
              turnstileToken={turnstileToken || ''}
              onTurnstileVerify={handleTurnstileVerify}
              industryQuestion="What industry does your company operate in?"
              companySizeQuestion="How many employees does your company have?"
              teamTypeQuestion="What type of team do you work in?"
              geographyQuestion="In which region is your team primarily based?"
            />
          )}
          {(currentPage === 'results' && scores && userData) && 
            <ResultsPage 
              scores={scores} 
              subheading={getSubheading()} 
              longFormAnswer={userData.longFormAnswer}
              industry={industry}
              companySize={companySize}
              surveyId={userData.surveyId}
            />
          }
        </div>
      </div>
      <SpeedInsights />
    </div>
  );
};

export default App;