import React from 'react';

const LogoHeader: React.FC = () => {
  return (
    <div className="mb-8">
      <img src="/images/plgeek-dark-version-full-color-rgb.svg" alt="Product-Led Geek Logo" className="w-64 h-auto mx-auto" />
    </div>
  );
};

export default LogoHeader;
