import React from 'react';
import { motion } from 'framer-motion';

interface LandingPageProps {
  onStartAssessment: () => void;
}

const LandingPage: React.FC<LandingPageProps> = ({ onStartAssessment }) => {
  return (
    <div className="landing-page flex flex-col justify-center items-center bg-primary text-white">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="mb-8"
      >
        <img src="/images/plgeek-dark-version-full-color-rgb.svg" alt="Product-Led Geek Logo" className="w-64 h-auto" />
      </motion.div>
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
        className="text-6xl font-bold mb-8 text-center"
      >
        <span className="bg-clip-text text-transparent" style={{ color: '#9D89FA' }}>
          Learning Velocity Index
        </span>
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
        className="text-2xl mb-12 text-center max-w-2xl"
      >
        Discover how your growth team's ability to learn and leverage learnings stacks up in the product-led world.
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6, ease: "easeOut" }}
        className="space-y-4"
      >
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onStartAssessment}
          className="bg-accent hover:bg-opacity-80 text-primary font-bold py-3 px-8 rounded-full text-xl transition duration-300 ease-in-out hover:shadow-lg"
        >
          Start Assessment
        </motion.button>
        <p className="text-center text-sm opacity-70">
          Takes about 2 minutes to complete
        </p>
      </motion.div>
    </div>
  );
};

export default LandingPage;
