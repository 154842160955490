import React, { useEffect, useState, useRef } from 'react';
import { Scores } from '../utils/scoreCalculations';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { ResponsiveRadar } from '@nivo/radar';
import LogoHeader from './LogoHeader';
import { supabase } from '../supabaseClient';
import { useTooltip } from '@nivo/tooltip';

interface ResultsPageProps {
  scores: Scores;
  subheading: string;
  longFormAnswer: string;
  industry: string;
  companySize: string;
  surveyId: number;
}

interface IndividualScore {
  question: string;
  score: number;
  category: string;
}

interface CustomLayerProps {
  innerWidth: number;
  innerHeight: number;
}

export interface Advice {
  questionId: number;
  score: number;
  adviceText: string;
}

export interface UserData {
  scores: Scores;
  advice: Advice[];
  longFormAnswer: string;
  subheading: string;
  radarChartData: {
    radarData: RadarDataPoint[];
    datasets: Array<{
      id: string;
      data: number[];
      color: string;
    }>;
  };
}

interface IndividualScoreAvg {
  question_id: number;
  avg_score: number;
}

interface AverageScores {
  overallScore: number;
  learningScore: number;
  leverageScore: number;
  individualScores: IndividualScoreAvg[];
  count: number; // Add this line
}

interface AverageData {
  overall: AverageScores;
  industry: AverageScores;
  companySize: AverageScores;
}

interface RadarDataPoint {
  question: string;
  [key: string]: number | string;
}

const colorMap: { [key: string]: string } = {
  'Your Score': '#C2FF09',
  'Overall Average': '#9D89FA',
  'Industry Average': '#FFAA5A',
  'Company Size Average': '#55C1FF',
};

const ResultsPage: React.FC<ResultsPageProps> = ({ scores, subheading, longFormAnswer, industry, companySize, surveyId }) => {
  const [advice, setAdvice] = useState<Advice[]>([]);
  const [averageData, setAverageData] = useState<AverageData | null>(null);
  const [showOverallAverage, setShowOverallAverage] = useState(false);
  const [showIndustryAverage, setShowIndustryAverage] = useState(false);
  const [showCompanySizeAverage, setShowCompanySizeAverage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [questionAverages, setQuestionAverages] = useState<Array<{
    question_id: number;
    overall_avg: number;
    overall_count: number;
    industry_avg: number;
    industry_count: number;
    company_size_avg: number;
    company_size_count: number;
  }>>([]);
  const [radarData, setRadarData] = useState<RadarDataPoint[]>([]);
  const [radarKeys, setRadarKeys] = useState<string[]>([]);
  const [adviceSummary, setAdviceSummary] = useState<string | null>(null);
  const [topActions, setTopActions] = useState<string[] | null>(null);
  const [isDetailedAdviceVisible, setIsDetailedAdviceVisible] = useState(false);

  useEffect(() => {
    if (scores && scores.individualScores && scores.individualScores.length > 0) {
      fetchAdvice();
      fetchAverageData();
      fetchAdviceSummary();
    }
  }, [scores]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Average data updated:', averageData);
    }
  }, [averageData]);

  const fetchAdvice = async () => {
    const { data, error } = await supabase
      .from('advice')
      .select('*')
      .in('question_id', scores.individualScores.map(score => parseInt(score.question.slice(1))))
      .order('question_id, score');

    if (error) {
      console.error('Error fetching advice:', error);
    } else if (data) {
      const formattedAdvice = data.map(item => ({
        questionId: item.question_id,
        score: item.score,
        adviceText: item.advice_text
      }));
      setAdvice(formattedAdvice);
    }
  };

  const fetchAverageData = async () => {
    const { data, error } = await supabase.rpc('get_average_scores_with_individuals', {
      user_industry: industry,
      user_company_size: companySize,
    });
    if (error) {
      console.error('Error fetching average data:', error);
    } else if (data && data.length > 0) {
      const result = data[0];
      setAverageData({
        overall: { ...result.overall, count: result.overall_count },
        industry: { ...result.industry, count: result.industry_count },
        companySize: { ...result.company_size, count: result.company_size_count },
      });
    }
  };

  const fetchAdviceSummary = async () => {
    try {
      const { data, error } = await supabase
        .from('surveys')
        .select('advice_summary, top_actions')
        .eq('id', surveyId)
        .single();

      if (error) throw error;

      if (data) {
        setAdviceSummary(data.advice_summary);
        setTopActions(data.top_actions);
      }
    } catch (error) {
      console.error('Error fetching advice summary:', error);
    }
  };

  const questionLabels = [
    "Experiment Frequently",
    "Proactively Discover User & Customer Insights",
    "Consistently Analyse Experiment Outcomes",
    "Rapidly Adapt Execution Based on Learnings",
    "Respond Positively to Failed Experiments",
    "Share Ideas Freely",
    "Make Data-Informed Decisions",
    "Regularly Reflect on Learnings",
    "Proactively Share Learnings Across Teams",
    "Enable Cross-Team Efforts Based on Learnings",
    "Build Effective Knowledge Systems",
    "Involve Leaders in Learning Discussions"
  ];

  useEffect(() => {
    if (!scores || !averageData) return;

    const data: RadarDataPoint[] = [];
    const keys: string[] = ['Your Score'];

    // Add these conditionally based on user selections
    if (showOverallAverage) {
      keys.push('Overall Average');
    }
    if (showIndustryAverage) {
      keys.push('Industry Average');
    }
    if (showCompanySizeAverage) {
      keys.push('Company Size Average');
    }

    // Map user's individual scores with labels from questionLabels
    scores.individualScores.forEach((score, index) => {
      data.push({
        question: questionLabels[index], // Use the label from questionLabels
        'Your Score': score.score,
      });
    });

    // Map overall average scores
    if (showOverallAverage && averageData.overall?.individualScores) {
      averageData.overall.individualScores.forEach((avgScore) => {
        const index = avgScore.question_id - 1; // Ensure this index aligns with questionLabels
        if (data[index]) {
          data[index]['Overall Average'] = avgScore.avg_score;
        }
      });
    }

    // Map industry average scores
    if (showIndustryAverage && averageData.industry?.individualScores) {
      averageData.industry.individualScores.forEach((avgScore) => {
        const index = avgScore.question_id - 1;
        if (data[index]) {
          data[index]['Industry Average'] = avgScore.avg_score;
        }
      });
    }

    // Map company size average scores
    if (showCompanySizeAverage && averageData.companySize?.individualScores) {
      averageData.companySize.individualScores.forEach((avgScore) => {
        const index = avgScore.question_id - 1;
        if (data[index]) {
          data[index]['Company Size Average'] = avgScore.avg_score;
        }
      });
    }

    setRadarData(data);
    setRadarKeys(keys);
  }, [scores, averageData, showOverallAverage, showIndustryAverage, showCompanySizeAverage]);

  const getRelevantAdvice = (questionId: number, score: number) => {
    const questionAdvice = advice.filter(a => a.questionId === questionId);
    if (questionAdvice.length === 0) {
      return "Advice not available at this time.";
    }
    const relevantAdvice = questionAdvice.reduce((prev, curr) => {
      return (Math.abs(curr.score - score) < Math.abs(prev.score - score)) ? curr : prev;
    });
    return relevantAdvice.adviceText;
  };

  const getMatrixQuadrant = () => {
    const { learningScore, leverageScore } = scores;
    if (learningScore < 60 && leverageScore < 60) return "Stagnant Learning";
    if (learningScore >= 60 && leverageScore < 60) return "Wasted Learning";
    if (learningScore >= 60 && leverageScore >= 60) return "Activated Learning";
    return "N/A";
  };

  // Prepare data for Nivo charts
  const scatterData = [
    {
      id: 'Your Score',
      data: [{ x: scores.learningScore, y: scores.leverageScore }],
      color: colorMap['Your Score'],
    },
    ...(showOverallAverage && averageData?.overall?.count !== undefined && averageData.overall.count >= 3
      ? [
          {
            id: 'Overall Average',
            data: [
              { x: averageData.overall.learningScore, y: averageData.overall.leverageScore },
            ],
            color: colorMap['Overall Average'],
          },
        ]
      : []),
    ...(showIndustryAverage && averageData?.industry?.count !== undefined && averageData.industry.count >= 3
      ? [
          {
            id: 'Industry Average',
            data: [
              { x: averageData.industry.learningScore, y: averageData.industry.leverageScore },
            ],
            color: colorMap['Industry Average'],
          },
        ]
      : []),
    ...(showCompanySizeAverage && averageData?.companySize?.count !== undefined && averageData.companySize.count >= 3
      ? [
          {
            id: 'Company Size Average',
            data: [
              { x: averageData.companySize.learningScore, y: averageData.companySize.leverageScore },
            ],
            color: colorMap['Company Size Average'],
          },
        ]
      : []),
  ].filter(item => item.data[0].x !== 0 || item.data[0].y !== 0);

  // Debug logging
  if (process.env.NODE_ENV !== 'production') {
    console.log('Scores object:', scores);
    console.log('Radar Data:', radarData);
    console.log('Radar keys:', ['value', ...(showOverallAverage ? ['overall'] : []), ...(showIndustryAverage ? ['industry'] : []), ...(showCompanySizeAverage ? ['companySize'] : [])]);
    console.log('Scatter data:', scatterData);
  }

  const CustomLayer = ({ innerWidth, innerHeight }: CustomLayerProps) => {
    const quadrantLabels = [
      { x: 30, y: innerHeight - 30, label: 'Stagnant Learning' },
      { x: innerWidth - 30, y: innerHeight - 30, label: 'Wasted Learning' },
      { x: innerWidth - 30, y: 30, label: 'Activated Learning' },
    ];

    return (
      <g pointerEvents="none">
        <rect x={0} y={0} width={innerWidth / 2} height={innerHeight / 2} fill="#9D89FA" fillOpacity={0.1} />
        <rect x={innerWidth / 2} y={0} width={innerWidth / 2} height={innerHeight / 2} fill="#C2FF09" fillOpacity={0.1} />
        <rect x={0} y={innerHeight / 2} width={innerWidth / 2} height={innerHeight / 2} fill="#101827" fillOpacity={0.1} />
        <rect x={innerWidth / 2} y={innerHeight / 2} width={innerWidth / 2} height={innerHeight / 2} fill="#9D89FA" fillOpacity={0.1} />
        <line x1={0} y1={innerHeight / 2} x2={innerWidth} y2={innerHeight / 2} stroke="#fff" strokeOpacity={0.5} />
        <line x1={innerWidth / 2} y1={0} x2={innerWidth / 2} y2={innerHeight} stroke="#fff" strokeOpacity={0.5} />
        {quadrantLabels.map((ql, i) => (
          <text key={i} x={ql.x} y={ql.y} fill="#fff" fontSize={10} textAnchor={i === 0 ? 'start' : 'end'}>
            {ql.label}
          </text>
        ))}
      </g>
    );
  };

  const CustomTooltipLayer = () => {
    const { showTooltipFromEvent, hideTooltip } = useTooltip();
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleMouseMove = (event: React.MouseEvent) => {
      showTooltipFromEvent(
        (
          <div
            style={{
              background: '#1f2937',
              color: '#f9fafb',
              padding: '9px 12px',
              border: '1px solid #ffffff',
              borderRadius: '5px',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
            }}
          >
            {scatterData.map((series) => (
              <div key={series.id} style={{ marginBottom: '6px' }}>
                <strong style={{ color: colorMap[series.id] }}>{series.id}</strong>
                <br />
                Learning Score: {series.data[0].x.toFixed(2)}
                <br />
                Leverage Score: {series.data[0].y.toFixed(2)}
              </div>
            ))}
          </div>
        ),
        event
      );
    };

    return (
      <rect
        x={0}
        y={0}
        width="100%"
        height="100%"
        fill="transparent"
        onMouseMove={handleMouseMove}
        onMouseLeave={hideTooltip}
        pointerEvents="all"
      />
    );
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Radar data updated:', radarData);
    }
  }, [radarData, showOverallAverage, showIndustryAverage, showCompanySizeAverage]);

  return (
    <div className="results-page">
      <LogoHeader />
      <h1 className="text-3xl font-bold mb-4 text-center">
        Your Learning Velocity Index Results
      </h1>
      <h2 className="text-xl text-accent mb-6 text-center">{subheading}</h2>
      
      <div className="mb-4 text-center">
        <p><strong className="text-[#C2FF09]">Industry:</strong> {industry}</p>
        <p><strong className="text-[#C2FF09]">Company Size:</strong> {companySize}</p>
      </div>
      
      {/* Centered Aggregate Scores Section */}
      <div className="mb-8 text-center">
        <div className="inline-block">
          <p className="mb-2 font-semibold">Learning Score: <span className="text-accent">{scores.learningScore}</span></p>
          <p className="mb-2 font-semibold">Leverage Score: <span className="text-accent">{scores.leverageScore}</span></p>
          <p className="mb-2 font-semibold">Overall Score: <span className="text-accent">{scores.overallScore}</span></p>
          <p className="mb-2 font-semibold">Matrix Quadrant: <span className="text-accent">{getMatrixQuadrant()}</span></p>
        </div>
      </div>
      
      <div className="flex flex-col items-center mb-4">
        <div className="flex items-center space-x-2 mb-2">
          {/* Color Swatch */}
          <div
            style={{
              backgroundColor: colorMap['Overall Average'],
              width: '12px',
              height: '12px',
              borderRadius: '50%',
            }}
          ></div>
          {/* Input and Label */}
          <input
            type="checkbox"
            checked={showOverallAverage}
            onChange={() => setShowOverallAverage(!showOverallAverage)}
            disabled={averageData?.overall?.count !== undefined && averageData.overall.count <= 3}
            className={`form-checkbox h-5 w-5 ${
              averageData?.overall?.count !== undefined && averageData.overall.count <= 3
                ? 'text-gray-400'
                : 'text-blue-600'
            }`}
          />
          <span>
            Show Overall Average
          </span>
        </div>
        <div className="flex items-center space-x-2 mb-2">
          {/* Color Swatch */}
          <div
            style={{
              backgroundColor: colorMap['Industry Average'],
              width: '12px',
              height: '12px',
              borderRadius: '50%',
            }}
          ></div>
          {/* Input and Label */}
          <input
            type="checkbox"
            checked={showIndustryAverage}
            onChange={() => setShowIndustryAverage(!showIndustryAverage)}
            disabled={averageData?.industry?.count !== undefined && averageData.industry.count <= 3}
            className={`form-checkbox h-5 w-5 ${
              averageData?.industry?.count !== undefined && averageData.industry.count <= 3
                ? 'text-gray-400'
                : 'text-blue-600'
            }`}
          />
          <span>
            Show Industry Average ({industry})
          </span>
        </div>
        <div className="flex items-center space-x-2">
          {/* Color Swatch */}
          <div
            style={{
              backgroundColor: colorMap['Company Size Average'],
              width: '12px',
              height: '12px',
              borderRadius: '50%',
            }}
          ></div>
          {/* Input and Label */}
          <input
            type="checkbox"
            checked={showCompanySizeAverage}
            onChange={() => setShowCompanySizeAverage(!showCompanySizeAverage)}
            disabled={
              averageData?.companySize?.count !== undefined && averageData.companySize.count <= 3
            }
            className={`form-checkbox h-5 w-5 ${
              averageData?.companySize?.count !== undefined && averageData.companySize.count <= 3
                ? 'text-gray-400'
                : 'text-blue-600'
            }`}
          />
          <span>
            Show Company Size Average ({companySize})
          </span>
        </div>
      </div>
      
      {/* Scatter Plot */}
      <h3 className="text-2xl font-bold mb-4 text-center text-[#C2FF09]">Learning x Leverage Matrix</h3>
      <div className="mb-8" style={{ height: '400px' }}>
        {scatterData.length > 0 && scatterData.every(d => d.data[0].x !== 0 || d.data[0].y !== 0) ? (
          <ResponsiveScatterPlot
            data={scatterData}
            xScale={{ type: 'linear', min: 0, max: 120 }}
            yScale={{ type: 'linear', min: 0, max: 120 }}
            margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
            blendMode="normal"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              legend: 'Learning Score',
              legendPosition: 'middle',
              legendOffset: 46,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              tickValues: 5,
              format: (value) => `${value}`,
            }}
            axisLeft={{
              legend: 'Leverage Score',
              legendPosition: 'middle',
              legendOffset: -60,
              tickPadding: 5,
              tickSize: 5,
              tickRotation: 0,
              tickValues: 5,
              format: (value) => `${value}`,
            }}
            colors={({ serieId }) => colorMap[serieId.toString()] || '#C2FF09'}
            nodeSize={14}
            tooltip={({ node }) => (
              <div
                style={{
                  background: '#1f2937',
                  color: '#f9fafb',
                  padding: '9px 12px',
                  border: '1px solid #ffffff',
                  borderRadius: '5px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                }}
              >
                <strong style={{ color: colorMap[node.serieId] }}>{node.serieId}</strong>
                <br />
                Learning Score: {node.data.x.toFixed(2)}
                <br />
                Leverage Score: {node.data.y.toFixed(2)}
              </div>
            )}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: '#ffffff',
                    strokeWidth: 1,
                  },
                },
                ticks: {
                  line: {
                    stroke: '#ffffff',
                    strokeWidth: 1,
                  },
                  text: {
                    fill: '#ffffff',
                    fontSize: 12,
                  },
                },
                legend: {
                  text: {
                    fill: '#ffffff',
                    fontSize: 14,
                  },
                },
              },
              grid: {
                line: {
                  stroke: '#555555',
                  strokeWidth: 0.5,
                },
              },
              tooltip: {
                container: {
                  background: '#1f2937',
                  color: '#f9fafb',
                  fontSize: '14px',
                  borderRadius: '5px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                },
              },
            }}
            isInteractive={true}
            useMesh={true}
            layers={[
              'grid',
              'axes',
              'nodes',
              'markers',
              CustomLayer,
              CustomTooltipLayer, // Add this line
              'legends',
            ]}
          />
        ) : (
          <div>No valid data to display</div>
        )}
      </div>

      {/* Radar Chart */}
      <h3 className="text-2xl font-bold mb-4 text-center text-[#C2FF09]">LVI Radar</h3>
      <div className="mb-8" style={{ height: '500px' }}>
        {radarData.length > 0 && radarData.every(d => {
          return radarKeys.every(key => {
            const value = d[key];
            if (typeof value !== 'number') {
              return false;
            }
            return !isNaN(value);
          });
        }) ? (
          <ResponsiveRadar
            data={radarData}
            keys={radarKeys}
            indexBy="question"
            valueFormat=">-.2f"
            maxValue={20}
            margin={{ top: 70, right: 10, bottom: 120, left: 10 }} // Increased left margin
            borderColor={{ from: 'color' }}
            gridLevels={4}
            gridLabelOffset={18}
            dotSize={8}
            dotColor={{ theme: 'background' }}
            dotBorderWidth={2}
            colors={radarKeys.map(key => colorMap[key])}
            blendMode="normal"
            motionConfig="gentle"
            legends={[
              {
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: -100,
                translateY: 400,
                itemsSpacing: 14,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: 'circle',
                itemTextColor: '#fff',
              },
            ]}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fill: '#fff',
                    fontSize: 10,
                  },
                },
              },
              grid: {
                line: {
                  stroke: '#cccccc',
                  strokeWidth: 1,
                  strokeDasharray: '2 2',
                  opacity: 0.5,
                },
              },
              tooltip: {
                container: {
                  background: '#1f2937',
                  color: '#f9fafb',
                  fontSize: '14px',
                  borderRadius: '5px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                },
              },
              legends: {
                text: {
                  fill: '#fff',
                  fontSize: 12,
                },
              },
              labels: {
                text: {
                  fill: '#fff',
                  fontSize: 10,
                  fontWeight: 'bold',
                  wordWrap: 'normal',
                  maxWidth: 80, // Adjust this value as needed
                },
              },
            }}
          />
        ) : (
          <div>No valid data to display</div>
        )}
      </div>

      <div className="longform-answer-display mb-8">
        <h3 className="text-2xl font-bold mb-4 text-center text-[#C2FF09]">Biggest Obstacle to Learning and Leverage</h3>
        <p className="text-center">{longFormAnswer}</p>
      </div>
{/* Summary and Top Actions Section */}
{adviceSummary && topActions && (
  <div className="mb-8">
    <table className="w-full border-collapse">
      <thead>
        <tr>
          <th className="text-2xl font-bold p-4 text-[#C2FF09] border-b border-[#9D89FA]">Advice Summary</th>
          <th className="text-2xl font-bold p-4 text-[#C2FF09] border-b border-[#9D89FA]">Top 3 Actions to Improve</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-4 align-top">
            <p className="text-[#9D89FA]">{adviceSummary}</p>
          </td>
          <td className="p-4 align-top">
            <ol className="list-decimal list-inside">
              {topActions.map((action, index) => (
                <li key={index} className="text-[#9D89FA] mb-2">{action}</li>
              ))}
            </ol>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)}

      {/* Advice Section */}
      <div className="advice text-center">
        <h3 style={{ color: '#C2FF09', cursor: 'pointer' }} onClick={() => setIsDetailedAdviceVisible(!isDetailedAdviceVisible)}>
          {isDetailedAdviceVisible ? '▼ Hide Detailed Advice' : '▶ Show Detailed Advice'}
        </h3>
        {isDetailedAdviceVisible && (
          <>
            {scores.individualScores.map((score, index) => {
              const questionId = parseInt(score.question.slice(1));
              const adviceText = getRelevantAdvice(questionId, score.score);
              return (
                <div key={index} className="mb-6">
                  <p className="font-semibold text-center">{questionLabels[index]}</p>
                  <p className="text-center">Score: {score.score}</p>
                  <p className="text-[#9D89FA] text-center mt-2">{adviceText}</p>
                </div>
              );
            })}
          </>
        )}
      </div>

    </div>
  );
};

export default ResultsPage;