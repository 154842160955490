import React from 'react';
import { Question } from '../types';
import LogoHeader from './LogoHeader';
import CustomDropdown from './CustomDropdown';
import ProgressBar from './ProgressBar';

interface QuestionPageProps {
  questions: Question[];
  answers: Record<number, number>;
  onAnswerChange: (questionId: number, value: number) => void;
  category: 'LEARNING' | 'LEVERAGE';
  onNextPage: () => void;
  subheading: string;
  isPageValid: boolean;
  currentStep: number;
}

const QuestionPage: React.FC<QuestionPageProps> = ({
  questions,
  category,
  answers,
  onAnswerChange,
  onNextPage,
  subheading,
  isPageValid,
  currentStep,
}) => {
  const filteredQuestions = questions.filter(q => q.category === category);

  if (process.env.NODE_ENV !== 'production') {
    console.log('Filtered questions:', filteredQuestions);
    console.log('Current category:', category);
  }

  const handleAnswerChange = (questionId: number, selectedIndex: string) => {
    onAnswerChange(questionId, Number(selectedIndex));
  };

  return (
    <div className="question-page w-full max-w-3xl mx-auto px-4 py-8">
      <LogoHeader />
      <ProgressBar currentStep={currentStep} totalSteps={3} />
      <h1 className="text-4xl font-bold mb-4 text-center">The Product-Led Geek Learning Velocity Index</h1>
      <h2 className="text-2xl text-accent mb-8 text-center">{subheading}</h2>
      {filteredQuestions.map((question) => (
        <div key={question.id} className="mb-8">
          <p className="text-xl mb-3">{question.text}</p>
          <div className="relative pr-8">
            <CustomDropdown
              options={question.options.map(option => option.label)}
              value={answers[question.id] !== undefined ? question.options[answers[question.id]].label : ''}
              onChange={(selectedLabel) => {
                const selectedIndex = question.options.findIndex(option => option.label === selectedLabel);
                handleAnswerChange(question.id, selectedIndex.toString());
              }}
              placeholder="Select an option"
            />
            {answers[question.id] !== undefined && (
              <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
                <svg className="w-6 h-6 text-accent" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              </div>
            )}
          </div>
        </div>
      ))}
      <div className="flex justify-center">
        <button 
          onClick={onNextPage} 
          disabled={!isPageValid}
          className="mt-6 bg-accent hover:bg-opacity-80 text-primary font-bold py-3 px-6 rounded-lg text-xl disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {category === 'LEARNING' ? 'Next: Leverage' : 'Next: Final Question'}
        </button>
      </div>
      {!isPageValid && (
        <p className="text-warning mt-4 text-center text-lg">
          Please answer all questions before proceeding.
        </p>
      )}
    </div>
  );
};

export default QuestionPage;