import React, { useEffect, useRef } from 'react';
import LogoHeader from './LogoHeader';
import CustomDropdown from './CustomDropdown';

interface EmailPageProps {
  email: string;
  onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => Promise<void>;
  subheading: string;
  isEmailValid: boolean;
  industry: string;
  onIndustryChange: (value: string) => void;
  companySize: string;
  onCompanySizeChange: (value: string) => void;
  teamType: string;
  onTeamTypeChange: (value: string) => void;
  geography: string;
  onGeographyChange: (value: string) => void;
  isLoading: boolean;
  turnstileToken: string;
  onTurnstileVerify: (token: string) => void;
  industryQuestion: string;
  companySizeQuestion: string;
  teamTypeQuestion: string;
  geographyQuestion: string;
}

const EmailPage: React.FC<EmailPageProps> = ({
  email,
  onEmailChange,
  onSubmit,
  subheading,
  isEmailValid,
  industry,
  onIndustryChange,
  companySize,
  onCompanySizeChange,
  teamType,
  onTeamTypeChange,
  geography,
  onGeographyChange,
  isLoading,
  turnstileToken,
  onTurnstileVerify,
  industryQuestion,
  companySizeQuestion,
  teamTypeQuestion,
  geographyQuestion
}) => {
  const turnstileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    window.onloadTurnstileCallback = function () {
      if (turnstileRef.current) {
        window.turnstile.render(turnstileRef.current, {
          sitekey: '0x4AAAAAAAwx0ixkmfNtb8Z7',
          callback: onTurnstileVerify,
        });
      }
    };
  }, [onTurnstileVerify]);

  const isFormComplete = isEmailValid && industry && companySize && teamType && geography && turnstileRef.current;

  const industryOptions = [
    'Technology & Software',
    'Financial Services & FinTech',
    'Healthcare & Life Sciences',
    'Professional Services',
    'Manufacturing & Industrial',
    'Retail & E-commerce',
    'Education & EdTech',
    'Media & Entertainment',
    'Government & Public Sector',
    'Other'
  ];
  
  const companySizeOptions = ['1-10', '11-50', '51-200', '201-500', '501-1000', '1000+', '5000+'];
  const teamTypeOptions = ['Core Product', 'Product Growth', 'Marketing', 'Other'];
  const geographyOptions = ['North America', 'South America', 'Europe', 'Asia', 'Africa', 'Australia'];

  return (
    <div className="email-page w-full max-w-3xl mx-auto">
      <LogoHeader />
      <h1 className="text-4xl font-bold mb-4 text-center">The Product-Led Geek Learning Velocity Index</h1>
      <h2 className="text-2xl text-accent mb-8 text-center">{subheading}</h2>
      <p className="text-xl mb-4">Help us know more about your team:</p>
      <div className="my-2"></div> 
      <p className="text-lg mb-2">{industryQuestion}</p>
      <CustomDropdown
        options={industryOptions}
        value={industry}
        onChange={onIndustryChange}
        placeholder="Select Industry"
      />
      <div className="my-2"></div> 
      <p className="text-lg mb-2">{companySizeQuestion}</p>
      <CustomDropdown
        options={companySizeOptions}
        value={companySize}
        onChange={onCompanySizeChange}
        placeholder="Select Company Size"
      />
      <div className="my-2"></div> 
      <p className="text-lg mb-2">{teamTypeQuestion}</p>
      <CustomDropdown
        options={teamTypeOptions}
        value={teamType}
        onChange={onTeamTypeChange}
        placeholder="Select Team Type"
      />
      <div className="my-2"></div> 
      <p className="text-lg mb-2">{geographyQuestion}</p>
      <CustomDropdown
        options={geographyOptions}
        value={geography}
        onChange={onGeographyChange}
        placeholder="Select Geography"
      />
      <div className="mt-12 border-t-2 border-accent pt-6">
        <p className="text-xl mb-4">Enter your email to view and receive your results.</p>
        <p className="text-xl mb-4">Submitting your email will also subscribe you to the Product-Led Geek newsletter and give you access to the PLGeek Growth Toolkit.</p>
        <p className="text-xl mb-4">You may unsubscribe at any time.</p>
        <input
          type="email"
          value={email}
          onChange={onEmailChange}
          className="w-full p-3 bg-transparent border-2 border-accent rounded-lg text-white text-lg mb-6"
          placeholder="Email"
        />
      </div>
      <div ref={turnstileRef} className="flex justify-center my-4"></div>
      <div className="flex justify-center">
        <button 
          onClick={onSubmit} 
          disabled={!isFormComplete}
          className="mt-6 bg-accent hover:bg-opacity-80 text-primary font-bold py-3 px-6 rounded-lg text-xl disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Submit
        </button>
      </div>
      {!isEmailValid && email.length > 0 && (
        <p className="text-warning mt-4 text-lg text-center">Please enter a valid email address.</p>
      )}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-primary p-6 rounded-lg shadow-lg">
            <p className="text-accent text-xl mb-4">Submitting Survey and Preparing Results...</p>
            <div className="loader"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailPage;