import React from 'react';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, totalSteps }) => {
  const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);

  return (
    <div className="flex justify-between items-center mb-8">
      {steps.map((step) => (
        <React.Fragment key={step}>
          <div className="flex flex-col items-center">
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                step <= currentStep ? 'bg-accent text-primary' : 'bg-gray-300 text-gray-600'
              }`}
            >
              {step}
            </div>
            <span className="text-sm mt-1">
              {step === 1 ? '' : step === 2 ? '' : ''}
            </span>
          </div>
          {step < totalSteps && (
            <div
              className={`flex-1 h-1 ${
                step < currentStep ? 'bg-accent' : 'bg-gray-300'
              }`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ProgressBar;