import React from 'react';
import LogoHeader from './LogoHeader';
import ProgressBar from './ProgressBar';

interface LongFormQuestionPageProps {
  answer: string;
  onAnswerChange: (answer: string) => void;
  onNextPage: () => void;
  subheading: string;
  isPageValid: boolean;
  currentStep: number;
}

const LongFormQuestionPage: React.FC<LongFormQuestionPageProps> = ({
  answer,
  onAnswerChange,
  onNextPage,
  subheading,
  isPageValid,
  currentStep,
}) => {
  return (
    <div className="longform-question-page w-full max-w-3xl mx-auto">
      <LogoHeader />
      <ProgressBar currentStep={currentStep} totalSteps={3} />
      <h1 className="text-4xl font-bold mb-4 text-center">The Product-Led Geek Learning Velocity Index</h1>
      <h2 className="text-2xl text-accent mb-8 text-center">{subheading}</h2>
      <p className="text-xl mb-4">What is the single biggest obstacle preventing your team from learning faster and leveraging learnings more effectively?</p>
      <textarea
        value={answer}
        onChange={(e) => onAnswerChange(e.target.value)}
        rows={8}
        className="w-full p-3 bg-transparent border-2 border-accent rounded-lg text-white text-lg mb-6"
      />
      <div className="flex justify-center">
        <button 
          onClick={onNextPage} 
          disabled={!isPageValid}
          className="mt-6 bg-accent hover:bg-opacity-80 text-primary font-bold py-3 px-6 rounded-lg text-xl disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
      <div className="flex justify-center">
        {!isPageValid && <p className="text-warning mt-4 text-lg">Please provide an answer of at least 8 characters.</p>}
      </div>
    </div>
  );
};

export default LongFormQuestionPage;